import { Link } from "gatsby"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <h1>
      <Link to="/">{siteTitle}</Link>
    </h1>
    <nav>
      <ul>
        <li><Link to="/about" activeClassName="active">About</Link></li>
        <li><Link to="/writing" activeClassName="active">Writing</Link></li>
        <li><Link to="/speaking" activeClassName="active">Speaking</Link></li>
        <li><Link to="/press" activeClassName="active">Press</Link></li>
        <li><Link to="/contact" activeClassName="active">Contact</Link></li>
        <li><Link to="/spend-guide" activeClassName="active">Spend Guide</Link></li>
      </ul>
    </nav>
  </header>
)

export default Header
