/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Location } from "@reach/router"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <Location>
        {({ location }) => {
          console.log(location.pathname)
          if (location.pathname !== '/spend-guide') {
            return <div className="teaser">
              <div className="teaser-container">
                <h3>Which brands deserve your money?</h3>
                <p>Wondering which kink events and fetish vendors care about <strong>antiracism</strong>, <strong>inclusion</strong>, <strong>representation</strong>, and <strong>safety</strong>? See my <strong>2021 Spend Guide</strong>, which I designed mostly for myself but may be useful for you too.</p>
                <p><Link to="/spend-guide" className="call-to-action">See the spend guide</Link></p>
              </div>
            </div>
          }
        }}
      </Location>
      <footer>
        <p>© 2020–{new Date().getFullYear()} <Link to="/">Preston “wexx” So</Link>. All rights reserved.</p>
        <p>Follow me on Instagram:
          {' '}
          <a href="https://instagram.com/mirubberxx" rel="noreferrer" target="_blank">@mirubberxx</a>
          {' • '}
          <a href="https://instagram.com/tight_connection" rel="noreferrer" target="_blank">@tight_connection</a>
        </p>
      </footer>
    </>
  )
}

export default Layout
